export default function HotelManage(ajax, config) {
  // const { domainName, IntentionServer } = config
  return {
    dictTypeList: (opt) => ajax({ url: `/dict/dict-type/list`, method: 'get', ...opt }),
    hotel: {
      list: (opt) => ajax({ url: `/hotel/page`, method: 'post', ...opt }),
      info: (opt) => ajax({ url: `/hotel/${opt.id}`, method: 'GET', ...opt }),
      geiImg: (opt) => ajax({ url: `/hotel/get-hotel-pictures`, method: 'post', ...opt }),
    },
    hotelBlacklist: {
      // 是否酒店黑名单-支持多个查询
      isHotelBlackListBatch: (opt) =>
        ajax({ url: '/hotel-black-list/is-hotel-black-list-batch', method: 'POST', ...opt }),
    },
  }
}
