export default function ProductCenterServer(ajax, config) {
  return {
    list: (opt) =>
      ajax({
        url: '/products',
        method: 'get',
        ...opt,
      }),
    listV2: (opt) =>
      ajax({
        url: '/v2/products',
        method: 'get',
        ...opt,
      }),
    listV2Post: (opt) =>
      ajax({
        url: '/v2/products',
        method: 'post',
        ...opt,
      }),
    // 产品打包资源查询
    productPackResource: (opt) =>
      ajax({
        url: '/product-pack-resource/query',
        method: 'post',
        ...opt,
      }),
    priceCalendar: (opt) =>
      ajax({
        url: '/product-price/calendar',
        method: 'post',
        ...opt,
      }),
    resourceList: (opt) =>
      ajax({
        url: '/resource/resources',
        method: 'post',
        ...opt,
      }),
    onlyProductDetail: (opt) =>
      ajax({
        url: '/only-product-details',
        method: 'get',
        ...opt,
      }),
    productDetail: (opt) =>
      ajax({
        url: '/product-details',
        method: 'get',
        ...opt,
      }),
    productResourceCalculate: (opt) =>
      ajax({
        url: '/product-resource/calculate',
        method: 'post',
        ...opt,
      }),
    // 单程票
    busFirstTicketList: (opt) =>
      ajax({
        url: '/bus/first-ticket-List',
        method: 'post',
        ...opt,
      }),
    // 往返票
    busSecondTicketList: (opt) =>
      ajax({
        url: '/bus/second-ticket-List',
        method: 'post',
        ...opt,
      }),
    // 套票
    busPackageTicketList: (opt) =>
      ajax({
        url: '/bus/package-ticket-List',
        method: 'post',
        ...opt,
      }),
    // 酒店服务
    hotelRoomService: (opt) =>
      ajax({
        url: '/hotel/product/room/service',
        method: 'post',
        ...opt,
      }),
  }
}
