export default {
  合作范围: '合作範圍',
  部门重复: '部門重複',
  最少保留一个: '最少保留一個',
  请您牢记: '請您牢記',
  账号: '賬號',
  组别: '組別',
  级别: '級別',
  开启: '開啟',
  是否确认重置密码: '是否確認重置密碼',
  密码重置成功: '密碼重置成功',
  新密码: '新密碼',
  '为保证数据安全，密码仅显示一次请您牢记': '為保證資料安全，密碼僅顯示一次請您牢記',
  开启账号后将恢复此账号权限确定开启所选账号吗:
    '開啟賬號後，將恢復此賬號許可權，確定開啟所選賬號嗎？',
  关闭账号后此账号权限将被收回确定关闭所选账号吗:
    '關閉賬號後，此賬號許可權將被收回，確定關閉所選賬號嗎？',
  账号开启成功: '賬號開啟成功',
  账号关闭成功: '賬號關閉成功',
  收款单详情: '收款單詳情',
  对账单详情: '對賬單詳情',
  'layout.noShowMenu.802015-0': '查看詳情',
  'layout.noShowMenu.802015-1': '添加類目',
  'layout.noShowMenu.802015-2': '編輯類目',
  'layout.noShowMenu.802015-3': '訂單詳情',
  'layout.noShowMenu.802015-4': '接單頁',
  'layout.noShowMenu.802015-5': '添加收款',
  'layout.noShowMenu.802015-6': '編輯收款',
  'layout.noShowMenu.802015-7': '收款詳情查看',
  'layout.noShowMenu.802015-8': '批量下單',
  'layout.noShowMenu.802015-9': '歷史記錄',
  'layout.noShowMenu.802015-10': '占位單處理',
  'layout.noShowMenu.802015-11': '取消占位單處理',
  'layout.noShowMenu.802015-12': '取消占位單查看',
  'layout.noShowMenu.802015-13': '占位單查看',
  'layout.noShowMenu.802015-14': '確認單處理',
  'layout.noShowMenu.802015-15': '批量確認',
  'layout.noShowMenu.802015-16': '團隊確認單查看',
  'layout.noShowMenu.802015-17': '團隊確認單處理',
  'layout.noShowMenu.802015-18': '添加團隊確認單',
  'layout.noShowMenu.802015-19': '取消確認單查看',
  'layout.noShowMenu.802015-20': '確認單查看',
  'layout.noShowMenu.802015-21': '核損單處理',
  'layout.noShowMenu.802015-22': '核損單查看',
  'layout.noShowMenu.802015-23': '憑證回傳處理',
  'layout.noShowMenu.802015-24': '憑證回傳查看',
  'layout.noShowMenu.802015-25': '票券詳情',
  'layout.noShowMenu.802015-26': '票券核銷',
  'layout.noShowMenu.802015-27': '價格調整詳情',
  'layout.noShowMenu.802015-28': '公司詳情',
  'layout.noShowMenu.802015-29': '門市詳情',
  'layout.noShowMenu.802015-30': '已關閉用戶',
  'layout.noShowMenu.802015-31': '新增/編輯傭金規則',
  'layout.noShowMenu.802015-32': '賬單明細',
  'layout.noShowMenu.802015-33': 'POI詳情',
  'layout.noShowMenu.802015-34': '入庫單詳情',
  'layout.noShowMenu.802015-35': '出庫單詳情',
  'layout.noShowMenu.802015-36': '資源詳情',
  'layout.noShowMenu.802015-37': '資源編輯',
  'layout.noShowMenu.802015-38': '行程編輯器',
  'layout.noShowMenu.802015-39': '產品編輯',
  'layout.noShowMenu.802015-40': '產品詳情',
  'layout.noShowMenu.802015-41': '結算單詳情',
  'layout.noShowMenu.802015-42': '團結算審核詳情',
  'layout.noShowMenu.802015-43': '結轉詳情',
  'layout.noShowMenu.802015-44': '毛利審批詳情',
  'layout.noShowMenu.802015-45': '代收代付詳情',
  'layout.noShowMenu.802015-46': '收款詳情',
  'layout.noShowMenu.802015-47': '收款匯總明細',
  'layout.noShowMenu.802015-473': '收款審核詳情',
  'layout.noShowMenu.802015-48': '創建預收單',
  'layout.noShowMenu.802015-49': '預收單詳情',
  'layout.noShowMenu.802015-50': '預收單修改',
  'layout.noShowMenu.802015-51': '預收款轉賬',
  'layout.noShowMenu.802015-52': '退款',
  'layout.noShowMenu.802015-53': '審核',
  'layout.noShowMenu.802015-54': '發票申請',
  'layout.noShowMenu.802015-55': '發票修改',
  'layout.noShowMenu.802015-56': '發票詳情',
  'layout.noShowMenu.802015-57': '預借發票申請',
  'layout.noShowMenu.802015-58': '預收款發票申請',
  'layout.noShowMenu.802015-59': '作廢申請',
  'layout.noShowMenu.802015-60': '開票',
  'layout.noShowMenu.802015-61': '退回',
  'layout.noShowMenu.802015-62': '作廢',
  'layout.noShowMenu.802015-63': '修改',
  'layout.noShowMenu.802015-64': '作廢詳情',
  'layout.noShowMenu.802015-65': '押金詳情',
  'layout.noShowMenu.802015-66': '退回申請',
  'layout.noShowMenu.802015-67': '退回查看',
  'layout.noShowMenu.802015-68': '充值明細詳情',
  'layout.noShowMenu.802015-69': '付款申請',
  'layout.noShowMenu.802015-70': '付款匯總明細',
  'layout.noShowMenu.802015-71': '付款退回',
  'layout.noShowMenu.802015-72': '付款申請詳情',
  'layout.noShowMenu.802015-73': '付款退款',
  'layout.noShowMenu.802015-74': '供應商對賬列表',
  'layout.noShowMenu.802015-75': '供應商對賬單詳情',
  'layout.noShowMenu.802015-76': '應付單詳情',
  'layout.noShowMenu.802015-77': '預付款審核',
  'layout.noShowMenu.802015-78': '預付款詳情',
  'layout.noShowMenu.802015-79': '成本票錄入',
  'layout.noShowMenu.802015-80': '成本票報賬',
  'layout.noShowMenu.802015-81': '成本票詳情',
  'layout.noShowMenu.802015-82': '收款詳情',
  'layout.noShowMenu.802015-83': '開銷項票申請',
  'layout.noShowMenu.802015-84': '結算詳情',
  'layout.noShowMenu.802015-85': '團收益分析詳情',
  'layout.noShowMenu.802015-86': '結算基礎項同步日誌',
  'layout.noShowMenu.802015-87': '結算結果列表',
  'layout.noShowMenu.802015-88': '結算項列表',
  'layout.noShowMenu.802015-89': '結轉項配置',
  'layout.noShowMenu.802015-90': '審核詳情',
  'layout.noShowMenu.802015-91': '活動詳情',
  'layout.noShowMenu.802015-92': '新增活動',
  'layout.noShowMenu.802015-93': '編輯活動',
  'layout.noShowMenu.802015-94': '維護主推',
  'layout.noShowMenu.802015-95': '新增航班號',
  'layout.noShowMenu.802015-96': '編輯航班號',
  'layout.noShowMenu.802015-97': '編輯合作渠道適用架構公司',
  'layout.noShowMenu.802015-98': '店主信息查看',
  'layout.noShowMenu.802015-99': '開店審核',
  'layout.noShowMenu.802015-100': '編輯店主信息',
  'layout.noShowMenu.802015-101': '額度分配',
  'layout.noShowMenu.802015-102': '查看訂單',
  'layout.noShowMenu.802015-103': '簽證管理詳情',
  'layout.noShowMenu.802015-104': '簽證材料',
  'layout.noShowMenu.802015-105': '認證材料',
  'layout.noShowMenu.802015-106': '簽證產品',
  'layout.noShowMenu.802015-107': '認證產品',
  'layout.noShowMenu.802015-108': '新增公告',
  'layout.noShowMenu.802015-109': '編輯公告',
  'layout.noShowMenu.802015-110': '公告詳情',
  'layout.noShowMenu.802015-111': '審批規則詳情',
  'layout.noShowMenu.802015-112': '企業客户員工列表',
  'layout.noShowMenu.802015-113': '活動產品資料統計表',
  'layout.noShowMenu.802015-114': '房型價格',
  'layout.noShowMenu.802015-115': '房型詳情',
  'layout.noShowMenu.802015-116': '編輯房型',
  'layout.noShowMenu.802015-117': '新增房型',
  'layout.noShowMenu.802015-118': '酒店詳情',
  'layout.noShowMenu.802015-119': '編輯酒店',
  'layout.noShowMenu.802015-120': '新增酒店',
  'layout.noShowMenu.802015-121': '編輯客商',
  'layout.noShowMenu.802015-122': '新增客商',
  'layout.noShowMenu.802015-123': '客商詳情',
  'layout.noShowMenu.802015-124': '客商審核詳情',
  'layout.noShowMenu.802015-125': '門券詳情',
  'layout.noShowMenu.802015-126': '銷售進度',
  'layout.noShowMenu.802015-127': '待辦詳情',
  'layout.noShowMenu.802015-128': '付款單申請詳情',
  'layout.noShowMenu.802015-129': '退款詳情',
  'layout.noShowMenu.802015-130': '付款單審核',
  'layout.noShowMenu.802015-131': '退款單審核',
  'layout.noShowMenu.802015-132': '轉出明細詳情',
  'layout.noShowMenu.802015-133': '退款明細詳情',
  'layout.noShowMenu.802015-134': '預付單審核詳情',
  'layout.noShowMenu.802015-135': '預付退款單審核詳情',
  'layout.noShowMenu.802015-136': '預收款明細',
  'layout.noShowMenu.802015-137': '修改預收單',
  'layout.noShowMenu.802015-138': '審批流詳情',
  'layout.noShowMenu.802015-139': '審批詳情',
  'layout.noShowMenu.802015-140': '會員管理',
  'layout.noShowMenu.802015-141': '會員詳情',
  'layout.noShowMenu.802015-142': '新增資訊',
  'layout.noShowMenu.802015-143': '編輯資訊',
  'layout.noShowMenu.802015-144': '退款審核詳情',
  'layout.noShowMenu.802015-145': '銷售日結詳情',
  'layout.noShowMenu.802015-146': '創建活動頁',
  'layout.noShowMenu.802015-147': '退款申請',
  'layout.noShowMenu.802015-148': '委託訂票單',
  内存在挂账后实收未参与统计点击可查看明细: '掛賬後實收未參與報表統計，收款金額{0} {1}，點擊',
  'layout.noShowMenu.743253-0': '團檔案詳情',
  'layout.noShowMenu.743253-1': '供應商對賬單詳情',
  'layout.noShowMenu.743253-2': '付款單審核詳情',
  'layout.noShowMenu.743253-3': '退款單審核詳情',
  'layout.noShowMenu.743253-4': '創建預收單',
  'layout.noShowMenu.743253-5': '預收單詳情',
  'layout.noShowMenu.743253-6': '預收單修改',
  'layout.noShowMenu.743253-7': '預收款轉賬',
  查看明细: '查看明細',
  详情: '詳情',
  测试环境: '測試環境',
  知识库: '知識庫',
  请输入关键词查找: '請輸入關鍵字查找',
  修改密码: '修改密碼',
  退出登录: '登出',
  已关闭: '已關閉',
  暂停营业: '暫停營業',
  '已注销/关闭': '已註銷/關閉',
  确定切换架构公司为: '確定切換架構公司為：{0}？',
  提示: '提示',
  活动已结束: '活動已結束',
  级公司: '級公司',
  国旅及所属企业: '國旅及所屬企業',
  中旅及所属企业: '中旅及所屬企業',
  其他: '其他',
  已启用: '已啟用',
  已停用: '已停用',
  否: '否',
  是: '是',
  一级公司: '一級公司',
  二级公司: '二級公司',
  三级公司: '三級公司',
  四级公司: '四級公司',
  五级公司: '五級公司',
  六级公司: '六級公司',
  七级公司: '七級公司',
  八级公司: '八級公司',
  九级公司: '九級公司',
  正餐: '正餐',
  简餐: '簡餐',
  不可退改: '不可退改',
  可退改: '可退改',
  系列团: '系列團',
  NBS旅行服务业务系统: '一體化旅行服務運營平臺',
  您没有菜单权限: '您沒有菜單權限',
  用户登录失效将重新登录: '用戶登錄失效,將重新登錄',
  错误: '錯誤',
  系统异常请稍后重试: '系統異常，請稍後重試！',
  成功: '成功',
  删除文件成功: '刪除文件成功',
  加载中: '加載中……',
  文件上传成功: '文件上傳成功',
  文件上传中: '文件上傳中',
  文件个数超过: '文件個數超過',
  文件超过: '文件超過',
  文件类型为: '文件類型為',
  我的待办: '我的待辦',
  部门电话: '部门電話',
  部门传真: '部门傳真',
  部门电邮: '部门電郵',
  文件格式: '文件格式',
  TT文件创建成功时间: 'TT文件创建成功時間',
  菜单置灰表示菜单的适用角色不包含当前角色类型: '置灰表示當前角色與菜單適用角色不匹配。',
  添加积分变动申请: '添加積分變動申請',
  积分变动申请详情: '積分變動申請詳情',
  更新附件: '更新附件',
  更新成功: '更新成功',
  確認更新附件: '確認更新附件',
  图片上传提示一:
    '請選擇行程相關的圖片，圖片尺寸不小於{width}*{height}像素或等比例最佳，大小不超過{size}M，數量不超過{max}張，圖片可能會居中裁剪為1:1的圖片在網站顯示，圖中若有文字儘量居中設置',
  图片上传提示二:
    '圖片尺寸不小於{width}*{height}像素，大小不超過{size}M，僅支持png、jpg、jpeg格式，單次最多上傳{max}張，圖片可能會居中裁剪為1:1的圖片在網站顯示，圖中若有文字儘量居中設置',
  图片上传提示三:
    '上傳圖片尺寸不小於{width}*{height}像素，大小不超過{size}M，圖片可能會居中裁剪為1:1的圖片在網站顯示，圖中若有文字儘量居中設置',
}
