export default {
  '006556-1': '資源類型',
  '006556-2': '資源名稱',
  '006556-3': '資源使用日期',
  '006556-4': '確認單類型',
  '006556-5': '供應商',
  '006556-6': '請輸入供應商名稱',
  '006556-7': '發起時間',
  '006556-8': '反饋渠道',
  '006556-10': '出發日期',
  '006556-11': '採購公司',
  '006556-12': '資源負責人',
  '006556-13': '確認單號',
  '006556-14': '請輸入確認單號',
  '006556-16': '發起人',
  '006556-17': '反饋人',
  '006556-18': '全部査詢',
  '006556-19': '常用査詢',
  '006556-20': '添加確認單',
  '006556-22': '套',
  '006556-23': '人數：',
  '006556-24': '大',
  '006556-25': '小',
  '006556-26': '單房差：',
  '006556-27': '份',
  '006556-28': '單房差',
  '006556-29': '確認單',
  '006556-30': '取消確認單',
  '006556-31': '已處理',
  '006556-32': '未處理',
  '006556-33': '系統對接',
  '006556-34': '人工反饋',
  '006556-35': '出遊人',
  '006556-37': '數量',
  '006556-38': '單價（成本/售價）',
  '006556-39': '總價（成本/售價）',
  '006556-41': '處理狀態',
  '006556-43': '供應商單號',
  '006556-44': '請選擇發起時間',
  '006556-45': '請輸入數字',
  '006556-46': '今天',
  '006556-47': '請先選擇訂單',
  '822103-0': '反饋信息',
  '822103-1': '確認結果',
  '822103-2': '供應商已確認',
  '822103-3': '供應商已退回',
  '822103-4': '成本項',
  '822103-5': '票號',
  '822103-6': '出遊人',
  '822103-7': '行程單',
  '822103-8': '供應商確認記錄',
  '822103-9': '上傳附件',
  '822103-10': '請選擇確認結果',
  '822103-11': '請輸入票號',
  '822103-12': '散客機票',
  '822103-13': '存在必填項未填寫',
  '822103-14': '清位時間必須大於當前時間',
  '526778-0': '請根據實際業務發生填寫成本項',
  '526778-1': '稅率',
  '526778-2': '當前所選成本項為六大成本項且團類型為',
  '526778-3': '團體團',
  '526778-15': '系列團',
  '526778-16': '，不可修改稅率',
  '526778-4': '支持格式doc、docx、xls、xlsx、txt、pdf、jpg、png、gif、bmp、csv（不超過20M）',
  '526778-5': '上傳文件',
  '526778-6': '請選擇成本項',
  '526778-7': '請輸入PNR',
  '526778-8': '上傳成功',
  '526778-9': '上傳文件大小不能超過20MB',
  '526778-10': '類型只能是doc、docx、xls、xlsx、txt、pdf、jpg、png、gif、bmp、csv',
  '526778-11': '文件名稱重複，請修改後重新上傳',
  '526778-12': '請等待文件上傳完成',
  '526778-13': '保存成功',
  '526778-14': '非六大成本項',
  '887849-0': '採購公司',
  '887849-1': '應付組織',
  '887849-2': '類型',
  '887849-3': '狀態',
  '887849-4': '確認結果',
  '887849-5': '明細信息',
  '887849-6': '確認單號',
  '887849-7': '交易號碼',
  '887849-8': '產品方交易號碼',
  '887849-9': '分銷方交易號碼',
  '887849-10': '產品方',
  '887849-11': '分銷方',
  '887849-12': '資源類型',
  '887849-13': '資源名稱',
  '887849-14': '資源負責人',
  '887849-16': '單價（成本）',
  '887849-17': '成人：',
  '887849-18': '兒童：',
  '887849-19': '單房差：',
  '887849-20': '數量',
  '887849-21': '套',
  '887849-22': '成本總價原幣',
  '887849-23': '成本項',
  '887849-24': '請選擇',
  '887849-25': '稅率',
  '887849-26': '請填寫稅率',
  '887849-27': '請輸入0-100的數',
  '887849-28': '六大成本項',
  '887849-30': '成本稅額原幣',
  '887849-30-1': '成本稅額本幣',
  exchangeRate: '匯率',
  '887849-32': '供應商確認記錄',
  '887849-33': '請上傳供應商確認記錄！',
  '887849-34': '上傳文件',
  '887849-35': '客人使用憑證',
  '887849-36': '不能超過500個字符',
  '887849-37': '操作',
  '887849-38': '移除',
  '887849-39': '置灰的單據為手工按訂單添加確認單，此類單據不可反饋供應商已退回',
  '887849-40': '批量上傳供應商確認記錄',
  '887849-41': '添加確認單',
  '887849-42': '備註',
  '887849-43': '請輸入內容',
  '887849-44': '下載出遊人',
  '887849-45': '下載確認單',
  '887849-46': '取消',
  '887849-47': '確認',
  '887849-48': '批量處理確認單成功',
  '887849-49': '是否覆蓋已上傳的供應商確認記錄？',
  '887849-50': '是',
  '887849-51': '否',
  '887849-52': '確認單{0}批量確認，確認單{1}上傳同樣的供應商確認記錄文件',
  '103718-0': '添加確認單',
  '103718-1': '團代號',
  '103718-2': '確認單號',
  '103718-3': '資源使用日期',
  '103718-4': '發起時間',
  '103718-5': '資源名稱',
  '103718-6': '資源負責人',
  '103718-9': '數量',
  '103718-10': '狀態',
  '266074-0': '發起日期',
  '266074-1': '供應商',
  '266074-2': '開戶行',
  '266074-3': '銀行賬號',
  '266074-4': '交易號碼',
  '266074-5': '供應商單號',
  '266074-7': '出發日期',
  '266074-8': '資源名稱',
  '266074-9': '資源類型',
  '266074-10': '反馈渠道',
  '266074-11': '聯絡人',
  '266074-12': '出遊人',
  '266074-13': '關聯出遊人',
  '266074-14': '大',
  '266074-15': '小',
  '266074-16': '變更',
  '266074-17': '姓名',
  '266074-18': '類型',
  '266074-19': '姓',
  '266074-20': '名',
  '266074-21': '性別',
  '266074-22': '國籍',
  '266074-23': '證件類型',
  '266074-24': '證件號碼',
  '266074-25': '證件有效期',
  '266074-26': '出生日期',
  '266074-27': '年齡',
  '266074-28': '手機',
  '266074-29': '變更前',
  '266074-30': '變更後',
  '266074-31': '結算信息',
  '266074-32': '成人',
  '266074-33': '兒童',
  '266074-34': '單房差',
  '266074-36': '庫存類型',
  '266074-37': '批次',
  '266074-38': '單價',
  '266074-39': '數量',
  '266074-40': '總價',
  '266074-41': '成人：',
  '266074-42': '兒童：',
  '266074-43': '單房差：',
  '266074-44': '套',
  '266074-45': '人數：',
  '266074-46': '份',
  '266074-47': '特殊需求',
  '266074-48': '全部',
  '266074-49': '占位單',
  '266074-50': '取消占位單',
  '266074-51': '確認單',
  '266074-52': '核損單',
  '266074-53': '取消確認單',
  '266074-54': '嬰兒',
  '266074-55': '男',
  '266074-56': '女',
  '266074-57': '身份證',
  '266074-58': '護照',
  '266074-59': '國際護照',
  '266074-60': '中國',
  '190368-0': '訂單信息',
  '190368-1': '產品方交易號碼',
  '190368-2': '分銷方交易號碼',
  '190368-3': '產品方',
  '190368-4': '分銷方',
  '190368-6': '採購公司',
  '190368-7': '應付組織',
  '190368-8': '資源類型',
  '190368-9': '成本項',
  '190368-10': '請根據實際業務發生填寫成本項',
  '190368-11': '資源名稱',
  '190368-12': '資源負責人',
  '190368-14': '計價方式',
  '190368-15': '按人計價',
  '190368-16': '按套計價',
  '190368-17': '原幣幣種',
  '190368-19': '財務設定匯率',
  '190368-20': '成本單價原幣',
  '190368-21': '單房差',
  '190368-22': '數量',
  '190368-23': '人',
  '190368-24': '份',
  '190368-25': '成本總價原幣',
  '190368-26': '稅率',
  '190368-27': '六大成本項',
  costOrigin: '成本單價原幣',
  '190368-29': '成本稅額原幣',
  '190368-30': '供應商確認記錄',
  '190368-31': '上傳文件',
  '190368-32': '支持格式doc、docx、xls、xlsx、txt、pdf、jpg、png、bmp、csv',
  '190368-33':
    '請務必下載本確認單，交由供應商確認，最終將供應商確認過的確認單上傳作為確認記錄附件。',
  '190368-34': '客人使用憑證',
  '190368-35': '備註',
  '190368-36': '保存並下載確認單',
  '190368-37': '±99999999之間的整數',
  '190368-38': '±99999999.99之間的數字，兩位小數',
  '190368-39': '請填寫單價',
  '190368-40': '請輸入成本單價原幣',
  '190368-41': '請填寫數量',
  '190368-42': '請輸入整數',
  '190368-43': '請選擇導遊領隊或供應商',
  '190368-44': '當前最新匯率為空，請聯系財務維護該幣種的最新匯率',
  '190368-45': '匯率不能為空',
  '190368-46': '人民幣',
  '190368-47': '請選擇採購公司',
  '190368-48': '請選擇資源類型',
  '190368-49': '請選擇成本項',
  '190368-50': '請輸入資源名稱',
  '190368-51': '請選擇資源負責人',
  '190368-52': '請選擇使用日期',
  '190368-53': '請選擇計價方式',
  '190368-54': '成本幣種不能為空',
  '190368-55': '請輸入成本總價',
  '190368-56': '請上傳供應商確認記錄',
  '190368-57': '不能超過500個字符',
  '190368-58': '請輸入0-100的數',
  '190368-59': '請輸入稅率',
  '190368-60': '添加確認單',
  '190368-61': '處理確認單',
  '190368-62': '間夜',
  '190368-63': '張',
  '190368-64': '套',
  '190368-65': '當前最新匯率為空，請聯系財務維護該幣種的最新匯率後，再提交。',
  '190368-66': '上傳文件大小不能超過20MB',
  '190368-67': '類型只能是doc、docx、xls、xlsx、txt、pdf、jpg、png、bmp、csv',
  '190368-68': '文件名稱重複，請修改後重新上傳',
  '190368-69': '删除成功',
  '190368-70': '上傳失敗',
  '190368-71': '上傳成功',
  '739188-0': '特殊需求',
  '739188-1': '保存',
  '933676-0': '資源信息',
  '933676-1': '交易號碼',
  '933676-3': '出發日期',
  '933676-4': '資源類型',
  '933676-5': '資源名稱',
  '933676-6': '銷售類型',
  '933676-7': '採購公司',
  '933676-8': '供應商',
  '933676-9': '導遊領隊',
  '933676-10': '反馈渠道',
  '933676-11': '聯絡人',
  '933676-12': '供應商單號',
  '933676-13': '方案',
  '933676-14': '關聯出遊人',
  '933676-15': '出遊人',
  '933676-16': '下載出遊人信息',
  '933676-17': '成本總價原幣',
  '933676-18': '成人',
  '933676-19': '單房差',
  '933676-20': '成本稅額原幣',
  '933676-21': '明細信息',
  '933676-22': '請輸入成人成本價',
  '933676-23': '請輸入大於0的成本價',
  '933676-24': '請最多輸入兩位小數',
  '933676-25': '請輸入兒童成本價',
  '933676-26': '請輸入嬰兒成本價',
  '987036-0': '類型',
  '987036-2': '庫存類型',
  '987036-3': '批次',
  '987036-4': '成本單價原幣',
  '987036-5': '單房差',
  '987036-6': '單房差：',
  '987036-7': '數量',
  '987036-8': '套',
  '987036-9': '人數：',
  '987036-10': '大',
  '987036-11': '小',
  '987036-12': '份',
  '987036-13': '成本總價原幣',
  '987036-14': '稅率',
  '987036-15': '成本稅額原幣',
  '987036-16': '請輸入成人成本價',
  '987036-17': '請輸入大於0的成本價',
  '987036-18': '請輸入整數',
  '987036-19': '請最多輸入兩位小數',
  '987036-20': '請輸入兒童成本價',
  '987036-21': '請輸入單房差成本價',
  '043788-0': '出遊人',
  '043788-1': '變更',
  '043788-2': '變更前',
  '043788-3': '變更後',
  '043788-4': '姓名',
  '043788-5': '類型',
  '043788-6': '性別',
  '043788-7': '證件類型',
  '043788-8': '證件號碼',
  '043788-9': '總價',
  '043788-10': '成人',
  '043788-11': '單房差',
  '043788-12': '明細信息',
  '043788-14': '場次：',
  '043788-15': '庫存類型',
  '043788-16': '批次',
  '043788-17': '成本單價原幣',
  costStandard: '成本單價本幣',
  '043788-18': '單房差：',
  '043788-19': '數量',
  '043788-20': '套',
  '043788-21': '人數：',
  '043788-22': '大',
  '043788-23': '小',
  '043788-24': '份',
  '043788-25': '成本總價原幣',
  '043788-26': '稅率',
  '043788-27': '成本稅額原幣',
  '043788-29': '確認結果',
  '043788-30': '供應商已確認',
  '043788-31': '實際供應商確認信息',
  '043788-32': '稍後補填',
  '043788-33': '同步填寫',
  '043788-34':
    '如只確認位置無法按訂單維度確認跟實際供應商的成本，請選擇【稍後補填】，如能按訂單維度確認實際供應商的成本，請選擇【同步填寫】',
  '043788-35': '請跟供應商確認成本後，在確認單清單添加團隊確認單',
  '043788-36': '成本項',
  '043788-37': '請選擇',
  '043788-38': '請根據實際業務發生填寫成本項',
  '043788-39':
    "當前所選成本項為六大成本項且團類型為${\n                        teamInfo.teamType === TEAM_TYPE.GROUP ? '團體團' : '系列團'\n                      }  ，不可修改税率",
  '043788-40': '供應商確認記錄',
  '043788-41': '上傳文件',
  '043788-42': '參考樣本',
  '043788-43': '支持格式doc、docx、xls、xlsx、txt、pdf、jpg、',
  '043788-44':
    '請務必下載本確認單，交由供應商確認，最終將供應商確認過的確認單上傳作為確認記錄附件。',
  '043788-45': '備註',
  '043788-46': '操作記錄',
  '043788-47': '下載確認單',
  '043788-48': '取消',
  '043788-49': '提交',
  '043788-50': '請選擇確認結果',
  '043788-51': '請選擇成本項',
  '043788-52': '請選擇稍後填寫/同步填寫',
  '043788-53': '請填寫供應商確認記錄',
  '043788-54': '請上傳供應商確認記錄',
  '043788-55': '請輸入0-100的數',
  '043788-56': '請輸入稅率',
  '043788-57': '非六大成本項',
  '043788-58': '申請人{0}尚未送簽，請優先到簽證平臺-送簽管理進行處理',
  '043788-59': '保存成功',
  '043788-60': '上傳文件大小不能超過20MB',
  '043788-61': '類型只能是doc、docx、xls、xlsx、txt、pdf、jpg、png、bmp、csv',
  '043788-62': '文件名稱重複，請修改後重新上傳',
  '043788-63': '删除成功',
  '043788-64': '上傳成功',
  '067834-0': '關聯出遊人',
  '067834-1': '大',
  '067834-2': '小',
  '067834-3': '下載出遊人信息',
  '067834-4': '姓名',
  '067834-5': '類型',
  '067834-6': '姓',
  '067834-7': '名',
  '067834-8': '性別',
  '067834-9': '證件類型',
  '067834-10': '簽發地',
  '067834-11': '證件號碼',
  '067834-12': '證件有效期',
  '067834-13': '出生日期',
  '067834-14': '年齡',
  '067834-15': '手機',
  '067834-16': '成本總價原幣',
  '067834-17': '單房差：',
  '067834-18': '成本稅額原幣',
  '067834-19': '明細信息',
  '067834-21': '場次：',
  '067834-22': '庫存類型',
  '067834-23': '批次',
  '067834-24': '成本單價原幣',
  '067834-25': '成人',
  '067834-26': '單房差',
  '067834-27': '數量',
  '067834-28': '套',
  '067834-29': '份',
  '067834-30': '稅率',
  '067834-32': '確認結果',
  '067834-33': '供應商已確認',
  '067834-93': '供應商已確認收客',
  '067834-94': '供應商已確認取消',
  '067834-34': '供應商已退回',
  '067834-35': '實際供應商確認信息',
  '067834-36': '稍後補填',
  '067834-37': '同步填寫',
  '067834-38':
    '如只確認位置無法按訂單維度確認跟實際供應商的成本，請選擇【稍後補填】，如能按訂單維度確認實際供應商的成本，請選擇【同步填寫】',
  '067834-39': '請跟供應商確認成本後，在確認單清單添加團隊確認單',
  '067834-40': '成本項',
  '067834-41': '請選擇',
  '067834-42': '請根據實際業務發生填寫成本項',
  '067834-43':
    "當前所選成本項為六大成本項且團類型為${\r\n                      workFormData?.teamType === TEAM_TYPE.GROUP ? ' 團體團' : '系列團'\r\n                    }  ，不可修改税率",
  '067834-44': '供應商確認記錄',
  '067834-45': '上傳文件',
  '067834-46': '參考樣本',
  '067834-47': '支持格式doc、docx、xls、xlsx、txt、pdf、jpg、png、gif、bmp、csv',
  '067834-48':
    '請務必下載本確認單，交由供應商確認，最終將供應商確認過的確認單上傳作為確認記錄附件。',
  '067834-49': '客人使用憑證',
  '067834-50': '票號',
  '067834-51': '出遊人',
  '067834-52': '航班信息',
  '067834-53': '請填寫票號',
  '067834-54': '行程單',
  '067834-55': '文件格式doc、xls、txt、pdf、jpg、png、gif、bmp、csv、docx、xlsx',
  '067834-56': '此憑證會發送給客人，請謹慎填寫！',
  '067834-57': '備註',
  '067834-58': '解決方案',
  '067834-59': '操作記錄',
  '067834-60': '下載確認單',
  '067834-61': '取消',
  '067834-62': '提交',
  '067834-63': '請輸入成人成本價',
  '067834-64': '請輸入大於0的成本價',
  '067834-65': '請輸入整數',
  '067834-66': '請最多輸入兩位小數',
  '067834-67': '請輸入兒童成本價',
  '067834-68': '請輸入單房差成本價',
  '067834-69': '請選擇確認結果',
  '067834-70': '請選擇稍後填寫/同步填寫',
  '067834-71': '請填寫供應商確認記錄',
  '067834-72': '請選擇成本項',
  '067834-73': '請填寫解決方案',
  '067834-74': '請上傳供應商確認記錄',
  '067834-75': '不能超過500個字符',
  '067834-76': '請輸入0-100的數',
  '067834-77': '請輸入稅率',
  '067834-78': '非六大成本項',
  '067834-79': '上傳失敗',
  '067834-80': '上傳成功',
  '067834-81': '删除成功',
  '067834-82': '上傳文件大小不能超過20MB',
  '067834-83': '類型只能是doc、docx、xls、xlsx、txt、pdf、jpg、png、bmp、csv',
  '067834-84': '類型只能是doc、docx、xls、xlsx、txt、pdf、jpg、png、gif、bmp、csv',
  '067834-85': '文件名稱重複，請修改後重新上傳',
  '067834-86': '申請人{0}尚未{1}，請優先到簽證平臺-送簽管理進行處理',
  '067834-87': '繼續提交',
  '067834-88': '送簽管理',
  '067834-89': '請等待文件上傳完成',
  '067834-90': '保存成功',
  '067834-91': '送簽',
  '067834-92': '終止',
  '084853-0': '類型：',
  '084853-1': '單號：',
  '084853-2': '狀態：',
  '084853-3': '退回原因：',
  '084853-4': '發起時間：',
  '084853-5': '全部',
  '084853-6': '占位單',
  '084853-7': '取消占位單',
  '084853-8': '確認單',
  '084853-9': '核損單',
  '084853-10': '取消確認單',
  '084853-11': '團隊確認單',
  '084853-12': '待處理',
  '084853-13': '已完成',
  '084853-14': '待財務審核',
  '084853-15': '財務審核退回',
  '096830-0': '交易號碼',
  '096830-1': '請輸入交易號碼',
  '096830-2': '團代號',
  '096830-3': '請輸入團代號',
  '096830-4': '確認單號',
  '096830-5': '請輸入單號',
  '096830-6': '供應商',
  '096830-7': '請輸入供應商',
  '096830-8': '資源類型',
  '096830-9': '請選擇',
  '096830-10': '負責人',
  '096830-11': '請輸入負責人',
  '096830-12': '發起人',
  '096830-13': '請輸入發起人',
  '096830-14': '反饋人',
  '096830-15': '請輸入反饋人',
  '096830-16': '資源負責人',
  '096830-17': '請輸入資源負責人',
  '096830-18': '資源名稱',
  '096830-19': '請輸入資源名稱',
  '096830-21': '請輸入資源編號',
  '096830-22': '單據類型',
  '096830-23': '請選擇單據類型',
  '096830-25': '至',
  '096830-26': '開始日期',
  '096830-27': '結束日期',
  '096830-28': '出發日期',
  '096830-29': '團名稱',
  '096830-30': '請輸入團名稱',
  '096830-31': '團類型',
  '096830-32': '請選擇團類型',
  '096830-34': '請輸入產品碼',
  '096830-36': '請輸入產品名稱',
  '096830-37': '狀態',
  '096830-38': '請選擇狀態',
  '096830-39': '確認結果',
  '096830-40': '請選擇確認結果',
  '096830-41': '反饋渠道',
  '096830-42': '請選擇反馈渠道',
  '096830-43': '採購公司',
  '096830-44': '請輸入採購公司',
  '096830-45': '發起時間',
  '096830-46': '開始時間',
  '096830-47': '結束時間',
  '096830-48': '反饋時間',
  '096830-49': '結束時間',
  '096830-50': '全部査詢',
  '096830-51': '常用査詢',
  '096830-52': '査詢',
  '096830-53': '導出明細',
  '096830-53-0': '單次不可導出超5000條數據',
  '096830-53-1': '出遊人明細',
  '096830-53-2': '確認單明細',
  '096830-54': '不能輸入大於2147483647的數字',
  '096830-55': '今天',
  '096830-56': '請選擇發起時間',
  '096830-57': '導出成功',
  '108207-0': '添加',
  '108207-1': '確認單號：',
  '108207-2': '狀態：',
  '108207-3': '採購公司',
  '108207-4': '應付組織',
  '108207-5': '資源類型',
  '108207-6': '請選擇',
  '108207-7': '成本項',
  '108207-8': '資源名稱',
  '108207-9': '請輸入資源名稱',
  '108207-10': '資源負責人',
  '108207-12': '選擇日期',
  '108207-13': '原幣幣種',
  '108207-15': '財務設定匯率',
  '108207-15-1': '比財務設定匯率',
  '108207-16': '成本單價原幣',
  '108207-17': '請輸入單價',
  '108207-18': '數量',
  '108207-19': '請輸入數量',
  '108207-20': '成本總價原幣',
  '108207-21': '請輸入成本總價',
  '108207-22': '稅率',
  '108207-23': '六大成本項',
  '108207-24':
    "當前所選成本項為六大成本項且團類型為${\r\n                    workFormData.teamType === TEAM_TYPE.GROUP ? ' 團體團' : '系列團'\r\n                  }  ，不可修改税率",
  '108207-25': '成本稅額原幣',
  '108207-26': '供應商確認記錄',
  '108207-27': '上傳文件',
  '108207-28': '參考樣本',
  '108207-29': '支持格式doc、docx、xls、xlsx、txt、pdf、jpg、png、bmp、csv、rar、zip',
  '108207-30':
    '請務必下載本確認單，交由供應商確認，最終將供應商確認過的確認單上傳作為確認記錄附件。',
  '108207-31': '客人使用憑證',
  '108207-32': '備註',
  '108207-33': '±99999999之間的整數',
  '108207-34': '±99999999.99之間的數字，兩位小數',
  '108207-35': '請輸入整數',
  '108207-36': '當前最新匯率為空，請聯系財務維護該幣種的最新匯率',
  '108207-37': '匯率不能為空',
  '108207-38': '請上傳供應商確認記錄',
  '108207-39': '請選擇採購公司',
  '108207-40': '請選擇供應商',
  '108207-41': '請選擇資源類型',
  '108207-42': '請選擇成本項',
  '108207-43': '請選擇使用日期',
  '108207-44': '當前最新匯率為空',
  '108207-45': '成本幣種不能為空',
  '108207-46': '請選擇資源負責人',
  '108207-47': '不能超過500個字符',
  '108207-48': '請輸入0-100的數',
  '108207-49': '請輸入稅率',
  '108207-50': '待處理',
  '108207-51': '已完成',
  '108207-52': '待財務審核',
  '108207-53': '財務審核退回',
  '108207-54': '當前最新匯率為空，請聯系財務維護該幣種的最新匯率後，再提交。',
  '108207-55': '請先選擇採購公司',
  '108207-56': '請選擇導遊領隊或供應商',
  '108207-57': '至少應有一條供應商確認信息',
  '108207-58': '確認删除當前實際供應商確認信息？',
  '108207-59': '提示',
  '108207-60': '確定',
  '108207-61': '取消',
  '120863-0': '已選',
  '120863-1': '個訂單',
  '120863-2': '勾選多個單據批量確認（供應商、確認單類型、狀態需要一致）',
  '120863-3': '批量確認',
  '120863-4': '供應商已確認',
  '120863-5': '供應商已退回',
  '120863-6': '按訂單添加確認單',
  '120863-7': '按團添加確認單',
  '120863-8': '自定義展示列',
  '120863-9': '確認單號',
  '120863-10': '團代號',
  '120863-11': '團名稱',
  '120863-12': '團類型',
  '120863-13': '交易號碼',
  '120863-14': '出發日期',
  '120863-16': '資源類型',
  '120863-17': '自組團業務專用，表示自營的跟團資源，可按團添加確認單確認成本',
  '120863-18': '自',
  '120863-19': '資源名稱',
  '120863-20': '資源負責人',
  '120863-24': '數量',
  '120863-25': '成本單價原幣',
  '120863-26': '成本總價原幣',
  '120863-27': '供應商',
  '120863-28': '負責人',
  '120863-29': '確認單類型',
  '120863-30': '發起時間',
  '120863-31': '狀態',
  '120863-32': '確認結果',
  反馈时间: '反饋時間',
  '120863-34': '反饋渠道',
  guestUsingCertificate: '使用憑證',
  '120863-36': '發起人',
  '120863-37': '反饋人',
  '120863-38': '採購公司',
  '120863-39': '操作',
  '120863-40': '處理',
  '120863-41': '下載',
  '120863-42': '詳情',
  '120863-43': '出遊人',
  '120863-44': '系統',
  '120863-45': '删除',
  '120863-46': '撤回',
  '120863-47': '1勾選一個單據',
  '120863-48': '2勾選一個有[自營]標記的確認單',
  '120863-49': '單價',
  '120863-50': '成本',
  '120863-51': '總價',
  '120863-52': '確定要删除{0}確認單？',
  '120863-53': '提示',
  '120863-54': '删除成功',
  '120863-55': '此單據將會從審批狀態撤回。 若您需要繼續發起，請重新提交並發起審批！',
  '120863-56': '撤回成功',
  '120863-57': '下載成功',
  '120863-58': '團隊',
  '134838-0': '變更',
  '134838-1': '變更前',
  '134838-2': '變更後',
  '134838-3': '姓名',
  '134838-4': '類型',
  '134838-5': '性別',
  '134838-6': '證件類型',
  '134838-7': '證件號碼',
  '134838-8': '證件有效期',
  '134838-9': '出生日期',
  '134838-10': '年齡',
  '134838-11': '手機',
  '337155-0': '團代號',
  '337155-1': '團名稱',
  '337155-2': '團期',
  '337155-3': '資源類型',
  '337155-4': '資源名稱',
  '337155-6': '採購公司',
  '337155-7': '應付組織',
  '345813-0': '資源信息',
  '345813-1': '團代號',
  '345813-3': '出發日期',
  '345813-4': '資源名稱',
  '345813-5': '供應商單號',
  '345813-6': '資源類型',
  '345813-7': '方案',
  '345813-8': '銷售類型',
  '345813-9': '聯絡人',
  '345813-10': '關聯出遊人',
  '345813-11': '出遊人',
  '345813-12': '下載出遊人信息',
  '345813-13': '變更',
  '345813-14': '姓名',
  '345813-15': '類型',
  '345813-16': '姓',
  '345813-17': '名',
  '345813-18': '性別',
  '345813-19': '證件類型',
  '345813-20': '證件號碼',
  '345813-21': '證件有效期',
  '345813-22': '出生日期',
  '345813-23': '年齡',
  '345813-24': '手機',
  '345813-25': '同行成人',
  '345813-26': '結算總價',
  '345813-27': '結算信息',
  '345813-28': '結算明細',
  '345813-30': '單價',
  '345813-31': '成本',
  '345813-32': '數量',
  '345813-33': '核損',
  '345813-34': '總價',
  '458141-0': '訂單信息',
  '458141-1': '確認金額匯總',
  '458141-2': '實際供應商確認信息',
  '458141-3': '備註',
  '458141-4': '操作記錄',
  '458141-5': '下載確認單',
  '458141-6': '取消',
  '458141-7': '提交',
  '458141-8': '請選擇確認結果',
  '458141-9': '請填寫供應商確認記錄',
  '458141-10': '請填寫解決方案',
  '458141-11': '請上傳供應商確認記錄',
  '458141-12': '上傳失敗',
  '458141-13': '上傳成功',
  '458141-14': '删除成功',
  '458141-15': '上傳文件大小不能超過20MB',
  '458141-16': '類型只能是doc、docx、xls、xlsx、txt、pdf、jpg、png、bmp、csv',
  '458141-17': '類型只能是doc、docx、xls、xlsx、txt、pdf、jpg、png、gif、bmp、csv',
  '458141-18': '文件名稱重複，請修改後重新上傳',
  '458141-19': '請等待文件上傳完成',
  '458141-20': '請檢查表單填寫是否正確。',
  '458141-21': '保存並下載成功。',
  '458141-22': '提交成功',
  '470519-0': '請選擇確認結果',
  '470519-1': '請選擇成本項',
  '470519-2': '請輸入票號',
  '470519-3': '請輸入PNR',
  '470519-4': '上傳成功',
  '470519-5': '上傳文件大小不能超過20MB',
  '470519-6': '提示',
  '470519-7': '類型只能是doc、docx、xls、xlsx、txt、pdf、jpg、png、gif、bmp、csv',
  '470519-8': '文件名稱重複，請修改後重新上傳',
  '470519-9': '請等待文件上傳完成',
  '470519-10': '保存成功',
  '476641-0': '類型：',
  '476641-1': '團隊確認單',
  '476641-2': '單號：',
  '476641-3': '狀態：',
  '476641-4': '待處理',
  '476641-5': '退回原因：',
  '476641-6': '發起時間：',
  '476641-7': '全部',
  '476641-8': '占位單',
  '476641-9': '取消占位單',
  '476641-10': '確認單',
  '476641-11': '核損單',
  '476641-12': '取消確認單',
  '476641-13': '已完成',
  '476641-14': '待財務審核',
  '476641-15': '財務審核退回',
  '485383-0': '添加',
  '485383-1': '單號：',
  '485383-2': '狀態：',
  '485383-3': '採購公司',
  '485383-4': '應付組織',
  '485383-5': '資源類型',
  '485383-6': '請選擇',
  '485383-7': '成本項',
  '485383-8': '資源名稱',
  '485383-9': '請輸入資源名稱',
  '485383-10': '資源負責人',
  '485383-12': '選擇日期',
  '485383-13': '原幣幣種',
  '485383-15': '財務設定匯率',
  '485383-16': '成本單價原幣',
  '485383-16-1': '本幣金額',
  '485383-17': '請輸入單價',
  '485383-18': '數量',
  '485383-19': '請輸入數量',
  '485383-20': '成本總價原幣',
  costTotalStandard: '成本總價本幣',
  '485383-21': '請輸入成本總價',
  '485383-22': '稅率',
  '485383-23': '六大成本項',
  '485383-24':
    "當前所選成本項為六大成本項且團類型為${\r\n                    workFormData.teamType === TEAM_TYPE.GROUP ? ' 團體團' : '系列團'\r\n                  }  ，不可修改税率",
  '485383-25': '成本稅額原幣',
  '485383-26': '供應商確認記錄',
  '485383-27': '上傳文件',
  '485383-28': '支持格式doc、docx、xls、xlsx、txt、pdf、jpg、png、bmp、csv、rar、zip',
  '485383-29':
    '請務必下載本確認單，交由供應商確認，最終將供應商確認過的確認單上傳作為確認記錄附件。',
  '485383-30': '客人使用憑證',
  '485383-31': '備註',
  '485383-32': '±99999999之間的整數',
  '485383-33': '±99999999.99之間的數字，兩位小數',
  '485383-34': '請輸入整數',
  '485383-35': '當前最新匯率為空，請聯系財務維護該幣種的最新匯率',
  '485383-36': '匯率不能為空',
  '485383-37': '請上傳供應商確認記錄',
  '485383-38': '請選擇資源負責人',
  '485383-39': '請選擇採購公司',
  '485383-40': '請選擇資源類型',
  '485383-41': '請選擇成本項',
  '485383-42': '請選擇使用日期',
  '485383-43': '成本幣種不能為空',
  '485383-44': '請輸入0-100的數',
  '485383-45': '請輸入稅率',
  '485383-46': '待處理',
  '485383-47': '已完成',
  '485383-48': '待財務審核',
  '485383-49': '財務審核退回',
  '485383-50': '請先選擇採購公司',
  '485383-51': '請選擇導遊領隊或供應商',
  '485383-52': '當前最新匯率為空，請聯系財務維護該幣種的最新匯率後，再提交。',
  '485383-53': '至少應有一條供應商確認信息',
  '485383-54': '確認删除當前實際供應商確認信息？',
  '485383-55': '提示',
  '485383-56': '確定',
  '485383-57': '取消',
  已核销: '已核銷',
  电子单据: '電子單據',
  凭证状态: '憑證狀態',
  上传凭证: '上傳憑證',
  未传完: '未傳完',
  已传完: '已傳完',
  无需上传: '無需上傳',
  文字凭证: '文字憑證',
  附件凭证: '附件憑證',
  certificateTip: '此憑證會發送給客人，請謹慎填寫。',
  提交凭证: '提交憑證',
  返点: '返點',
  来源单号: '來源單號',
}
