export default {
  index: {
    noPermissionTips: '您無權查看此訂單',
    orderCancel: '取消訂單',
    orderForceCancel: '強制退票',
    checkLossResult: '確認核損結果',
    signUpForPayment: '發起簽約收款',
    checkBills: '查看單據',
    issueTickets: '去出票',
    issueTicketsTips: '訂單所有資源均占位成功、附加項均審核通過、完成全額收款，是否‘去出票’？',
    // '订单改签已完成全额收款，是否‘确认改签‘?'
    confirmRebookTips: '訂單改簽已完成全額收款，是否‘確認改簽’？',
  },
  travelDemand: {
    status: '狀態',
    useCertificate: '使用憑證',
    occupy: '占位',
    cancelOccupy: '取消占位',
    cancelOccupyTips: '確定要取消占位嗎？',
    tryAgain: '重試',
    releaseTime: '清位時間',
    solution: '解決方案',
    reason: '原因',
    lossEffectiveTime: '方案有效期',
    resourceOwnerName: '資源負責人',
    roundType: '占位方式',
    changeDemand: '變更需求',
    confirmChange: '確認變更',
    notSalesTips: '原產品、出發日期、場次不可售賣，您需重新選擇',
    publicTips1: '1、若涉及變更已占位成功的資源，【確認變更】後，系統將自動取消占位並刪除資源',
    publicTips2: '2、若有新增資源，需重新發起占位',
    resourceOrCountChangeTips:
      '涉及變更已占位成功資源的場次，【確認變更】後，系統將自動取消占位並刪除資源，確定繼續操作？',
    requirementConfirmingTips: '請及時占位，占位成功後，會預留位子',
    occupyingTips: '請聯系{0}處理占位單',
    occupyFailedTips: '請查看失敗原因，重試或與客人溝通變更需求',
    confirmingTips: '請聯系{0}處理確認單',
    confirmFailedTips: '請查看解決方案，與客人溝通',
    cancelOrderLosingTips: '請聯系{0}處理核損單',
    cancelOrderLossFeedBackTips: '請確認核損結果，跟客人溝通取消損失，確認是否最終取消',
    allCancelConfirmingTips: '請聯系{0}處理確認單',
    cancelConfirmingFailTips: '請查看失敗原因，重試',
    deleteTips: '確定要刪除該資源嗎?',
    lastResourceDeleteTips: '最後一行資源不可刪除',
    roomResourceDeleteTips: '確認刪除，刪除後當前房型價格關聯的酒店服務也一並刪除?',
    addSuccess: '添加成功',
    deleteSuccess: '刪除成功',
  },
  additional: {
    title: '附加項',
    additionalTotalPrice: '其他附加項',
    additionalType: '附加項類型',
    explain: '說明',
    amount: '金額',
    additionalAmount: '附加項總額',
    additionalTips: '請聯系{0}審核，如無需調整，可刪除',
  },
  moneyInfo: {
    title: '收退款',
    receiptsType: '收款類型',
    receiptsCode: '收款單號',
    receiptMode: '收款方式',
    receiptStatus: '狀態',
    originalCurrency: '收款幣種',
    originalReceiptsAmount: '收款金額',
    receiptAmount: '換算後金額',
    receivableAmount: '待收金額',
    exchangeRate: '匯率',
    creditAmount: '掛賬金額',
    electronicDocument: '電子單據',
    refundType: '退款類型',
    refundMode: '退款方式',
    refundStatus: '退款狀態',
    refundCurrencyCode: '退款幣種',
    refundAmount: '退款金額',
    refundCode: '退款單號',
    待收总额: '待收總額',
    已收总额: '已收總額',
    待退总额: '待退總額',
    已退总额: '已退總額',
    挂账使用总额: '掛賬總額',
    receiptsTips: '請添加收款',
    refundTips: '請及時發起退款申請，並聯系財務處理退款',
    收款后自动缴印花费: '收款後自動繳印花稅',
    您可以在印花管理中查看处理结果: '請在【印花管理】查看處理結果。',
    收款提交成功自动缴印花费成功: '收款提交成功，自動繳印花稅成功',
    收款提交成功自动缴印花费失败: '收款提交成功，自動繳印花稅失敗',
    您可以分析失败原因处理后在印花管理中缴印花费:
      '自動繳印花失敗：{0}，請在【印花管理】查看處理結果。',
    收款已缴印花税不可作废收款:
      '收款已繳印花稅，不可作廢收款，您可以先退款，在退款後跟TIA申請退印花稅，最後重新錄單完成收款',
    征收编号: '征收編號',
    已缴印花: '已繳印花',
    印花征收时间: '印花征收時間',
    已退印花: '已退印花',
    stampTaxTips: '如需退印花稅費用，請在【印花管理】進行登記。',
    stampTaxTips1: '如需退印花稅費用，請在【印花管理】進行登記。',
    stampTaxTips2: '繳印花失敗：{0}，請在【印花管理】查看處理結果。',
    stampTaxTips3: '如需退印花稅費用，請在【印花管理】進行登記。',
  },
  contact: {
    deleteTips: '確定要删除該聯絡人嗎？',
  },
  tourist: {
    deleteTips: '確定要删除該出遊人嗎？',
  },
  memo: {
    title: '操作記錄',
  },
  lossInfo: {
    lossType: '核損類型',
    allCancel: '整單取消',
    partCancel: '部分取消',
    lossMode: '核損方式',
    systemLoss: '系統自動核損',
    peopleLoss: '人工核損',
    orderTotalAmountAfterChange: '預計訂單總價變化',
    refundableAmount: '預計退款金額',
    lossDetail: '損失明細',
    lossAmount: '損失金額',
    lossUnitPrice: '損失單價',
    lossTotalPrice: '損失總價',
    totalAmountBeforeChange: '預計金額變化',
    totalAmountAfterChange: '預計總價變化',
    lossEffectiveTime: '損失方案有效截止',
    lossEffectiveStatus: '已失效',
    损失总额: '損失總額',
    套餐总额: '套餐總額',
    cancelLossTips: '取消核損，當前核損結果失效，如客人仍需取消，請重新發起核損，確定繼續操作？',
    relaunchTips: '重新發起，當前核損結果失效，確定繼續操作？',
    confirmLossTips: '跟客人達成一致，認可當前核損方案，確定取消？',
    cancelLoss: '取消核損',
    relaunch: '重新發起',
    confirmAllCancel: '確認整單取消',
    confirmCancelLoss: '確認部分取消',
  },
  baseInfo: {
    订单状态: '訂單狀態：',
    团: '團',
    交易号码: '交易號碼：',
    复制: '複製',
    交易时间: '交易時間：',
    终端: '終端：',
    选择: '選擇',
    存在占位中的资源您可以联系产品负责人反馈占位结果再进行修改:
      '存在占位中的資源，您可以聯繫產品負責人回饋占位結果，再進行修改',
    修改成功: '修改成功',
    收据: '收據',
    发票: '發票',
    换票证: '換票證',
    团名称: '團名稱：',
    团号: '團號：',
    存在占位中的资源您可以联系产品负责人反馈占位结果再变更需求:
      '存在占位中的資源，您可以聯繫產品負責人#反饋占位結果，再變更需求',
    存在占位成功的资源您先点击取消占位取消占位成功后再进行修改:
      '存在占位成功的資源，您先點擊[取消占位] ，取消占位成功後再進行修改',
    更换渠道客户后已选中的资源价格会更新确定继续操作:
      '更換渠道/客户後已選中的資源價格會更新，確定繼續操作？',
  },
  topNav: {
    基本信息: '基本信息',
    出游需求: '出遊需求',
    附加项: '附加項',
    促销和费用: '促銷和費用',
    收退款: '收退款',
    出游人: '出遊人',
    联络人: '聯絡人',
    操作记录: '操作記錄',
  },
  bill: {
    printNum: '打印次數',
    sendNum: '發送次數',
    entrustedTicketList: '委託訂票單清單',
    billList: '收據清單',
    invoiceList: '發票清單',
    ticketManagementList: '使用憑證清單',
    generated: '已生成',
    voided: '已作廢',
    detail: '明細',
    voucherCode: '使用憑證號',
    voucher: '使用憑證',
    unwritten: '未核銷',
    written: '已核銷',
    printDetail: '列印記錄',
    sendDetail: '發送記錄',
    batchHandleTips: '請至少勾選一條收據/發票/使用憑證',
    printTips:
      '支持格式pdf、jpg、png的文件在線列印，如電子單據非上述格式，請下載後列印，或直接發送給客戶',
  },
  orderCancel: {
    occupyingTips: '存在占位中的資源，訂單無法取消',
    confirmingTips: '訂單中的資源正在跟供應商確認中，暫不可取消訂單，待確認完成後，可正常操作。。',
    cancelingTips: '訂單中正在取消處理中，不可取消訂單。',
    losingTips:
      '訂單中的資源正在跟供應商核損中，暫不可取消訂單，待核損完成且確認核損結果後，可正常操作。',
    reBookingTips: '存在改簽中的資源，訂單無法取消',
    reBookSuccessTips: '存在改簽票，訂單無法取消',
    cancelReason: '取消原因',
    cancelReasonTips: '請勾選取消原因',
    cancelRemarkTips: '請在輸入框填寫取消原因',
    cancelSuccessTips: '取消訂單申請成功',
    是否确定强制退订单: '是否確定強制退訂單',
    强制退成功: '強製退訂單申請成功',
    handlingFee: '取消手续费',
    changeNameIngTips: '訂單中正在改名處理中，不可取消訂單。',
  },

  changeName: {
    changeName: '改名',
    beforeChangeName: '改名前',
    afterChangeName: '改名后',
    confirmChangeName: '确认改名',
    cancelChangeName: '取消改名',
    applyChangeName: '申請改名',
    firstStep: '第1步 | 維護出遊人',
    secondStep: '第2步 | 關聯出遊人',
    thirdStep: '第3步 | 提交改名申請',
    beforeChange: '變更前',
    afterChange: '變更後',
    handlingFee: '改名手續費',
    waitLoss: '待核損',
    receivableTotalAmount: '改名應收總額',
    firstStepTips: '选择需要改名的出游人',
    secondStepTips: '填写改名信息',
    thirdStepTips: '點擊【提交】後，請聯系{0}處理核損單，再根據核損結果進行後續處理',
    confirmChangeNameTips:
      '如有改名應收，請先完成全額收款，收款後，點擊【確認】正式向供應商發起改名',
    confirmChangeNameBtnTips: '訂單改名已完成全額收款，是否‘確認改名’',
  },
}
